@import "../../assets/scss/styles.scss";

.packages {
  display: flex;
  align-items: center;
}

.what-we-do-well-paragraph {
  width: 90%;
}

.gold-pkg-headline-text {
  padding-left: 0 !important;
}

.edge-image {
  img {
    z-index: 10;
    position: fixed;
    object-fit: contain;
    bottom: 0;
    right: 0;
  }
}

@media screen and (max-width: 800px) {
  .edge-image {
    display: none;
  }

  .gold-pkg-headline-text {
    padding-left: 15px !important;
  }
}

@import "../../assets/scss/styles.scss";

.live-details-page-wrapper {
  width: 100%;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 3s;
}

.live-header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.live-header-description {
  width: 100%;
}

.live-section {
  padding: 20px;
  padding-right: 105px;
}

.live-header-menu {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 2rem;

  p {
    padding-right: 26px;
    &:last-of-type {
      padding-right: 0 !important;
    }
  }
}

.live-header-title {
  font-size: 24px !important;
  text-transform: uppercase;
  line-height: 13px;
  margin-right: 20px;
}

.live-gallery-wrapper {
  grid-template-rows: repeat(2, 180px) !important;
}

.live-details-img {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center;
}

.image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0 10px;
  grid-auto-rows: 10px;
}

.image-list img {
  width: 250px;
}

.live-right-arrow {
  background-image: url("../../assets/images/icons/right-arrow.svg");
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: contain;
  width: 80px !important;
  height: 25px !important;
}
.live-left-arrow {
  background-image: url("../../assets/images/icons/left-arrow.svg");
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: contain;
  width: 80px !important;
  height: 25px !important;
}

@media screen and (min-width: 1024px) {
  #wrapper {
    width: 100%;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      background: linear-gradient(to bottom, transparent, white);
      height: 40%;
      width: 100%;
      pointer-events: none;
    }
  }

  #scroller {
    width: 100%;
    height: 450px;
    overflow-y: auto;

    .live-detatils-text {
      margin-bottom: 100px;
    }
  }
}

@media screen and (max-width: 800px) {
  .live-details-page-wrapper {
    margin-top: 4rem;
  }

  .live-details-img {
    width: 90vw !important;
    height: 400px;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: center;
    margin-bottom: 20px;
  }

  .page-title {
    position: relative;
    transform: rotate(0);
    float: left;
    left: inherit;
    top: inherit;
  }

  .mt-2,
  .mt-3 {
    margin-top: 0 !important;
  }
}

// @media screen and (min-width: 800px) {
//   body {
//     overflow-y: auto;
//   }
// }
@media screen and (max-width: 400px) {
  .live-header-menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 2rem;
    justify-content: space-between;

    p {
      padding-right: 5px;
      &:last-of-type {
        padding-right: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .live-header-section {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 4rem;
  }

  .live-header-description {
    width: 100%;
  }

  .live-section {
    padding: 15px;
    padding-right: 15px;
  }
}

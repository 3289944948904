footer {
  width: 100%;
  padding: 10px 73px;

  &.with-reverse {
    padding-right: 55px;
  }

  .footer-container.reverse {
    flex-direction: row-reverse;
  }

  .copyright {
    max-width: 1440px;
    padding: 5px 20px;
    font-size: 8px !important;
  }

  .copyright-reverse {
    text-align: right;
    padding-right: 28px;
  }

  .footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;

    .footer-menu {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;

      .footer-menu-item {
        padding: 10px 0;
        margin-right: 10px;
        width: 80px;
        text-align: center;
        font-family: $third-font-family;
        text-transform: uppercase;
        font-size: 12px;

        &:hover {
          background-color: $primary-color;
          color: white;
          text-align: center;
        }

        .footer-menu-item-dropdown {
          display: none;
          position: absolute;
          bottom: 90%;
          min-height: 215px;
          min-width: 230px;
          left: inherit;
          text-align: left;
          background-color: #000000e0;
          padding: 10px;
          padding-bottom: 50px;
          font-family: $font-family;
          font-size: 14px;
          font-weight: normal;
          text-transform: capitalize;
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
          li {
            padding: 10px 0;
          }

          a {
            color: white !important;
            &:hover {
              color: $secondary-color !important;
            }
          }

          ul {
            display: none;

            li {
              margin-top: 10px;
              padding: 10px;
              font-weight: normal;
              text-transform: capitalize;

              &:hover > div {
                color: $secondary-color;
              }
            }
          }
        }

        &:hover .footer-menu-item-dropdown {
          display: block;
        }

        .footer-menu-item-dropdown-mega-menu {
          display: none;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: #000000e0;
          padding: 10px;
          padding-bottom: 50px;
          font-family: $font-family;
          font-size: 14px;
          font-weight: normal;
          text-transform: capitalize;
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
          justify-content: center;
          align-items: center;
          animation-name: fadeInOpacity;
          animation-iteration-count: 1;
          animation-timing-function: ease-in;
          animation-duration: 0.5s;

          .row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;

            .row-item {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              text-align: center;
              padding: 10px;

              .row-item-title {
                font-size: 12px !important;
                -webkit-margin-after: 0;
                margin-block-end: 0;
                -webkit-margin-before: 0;
                margin-block-start: 0;
              }
              .row-item-subtitle {
                font-size: 8px !important;
                -webkit-margin-before: 0;
                margin-block-start: 0;
              }
              .social-icon {
                -webkit-margin-before: 0;
                margin-block-start: 0;
              }

              .social-media-text {
                text-transform: lowercase;
              }

              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }

        &:hover .footer-menu-item-dropdown-mega-menu {
          display: block;
        }
      }
    }
  }

  &.sticky {
    bottom: 0 !important;
  }
}

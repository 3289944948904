.fullwidth-dark-bg {
  width: 100%;
  height: 100%;
  /* z-index: -1; */
  /* position: fixed; */
  /* top: 0;
  left: 0; */
  background: url("../../assets/background-images/background\ with\ stars\ 1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
}

@media only screen and (max-width: 1000px) {
  .page-wrapper {
    margin-top: 4rem;
  }
}

@import "../../assets/scss/styles.scss";

.training-header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.training-header-description {
  width: 40%;
}

.training-section {
  padding: 20px;
  padding-right: 105px;
}

.image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0 10px;
  grid-auto-rows: 10px;
}

.image-list img {
  width: 250px;
}

@media screen and (max-width: 800px) {
  .page-title {
    position: relative;
    transform: rotate(0);
    float: left;
    left: inherit;
    top: inherit;
  }

  .mt-2,
  .mt-3 {
    margin-top: 0 !important;
  }
}

// @media screen and (min-width: 800px) {
//   body {
//     overflow-y: auto;
//   }
// }
@media screen and (max-width: 800px) {
  .training-header-section {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 4rem;
  }

  .training-header-description {
    width: 100%;
  }

  .training-section {
    padding: 15px;
    padding-right: 15px;
  }
}

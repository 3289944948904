.mobile-menu-icon {
  display: none;
}

@media screen and (max-width: 800px) {
  nav.dark .menu-container .menu .menu-item {
    color: $primary-color !important;
  }

  nav.dark .menu-container .logo {
    background: url("../../../images/logos/logo.svg") !important;
    background-repeat: no-repeat;
  }

  nav {
    padding-top: 0 !important;
    padding: 10px 0;
    // height: 100%;
  }
  nav > .menu-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    background-color: white;
    border-bottom: 0.2px solid lightgray;
    -webkit-box-shadow: 0px 0px 13px -11px black;
    box-shadow: 0px 0px 13px -11px black;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    left: 15px;
    top: 17px;
  }

  .menu-wrapper {
    width: 100%;

    #desktop-menu {
      display: none;
    }

    .menu {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
      padding-top: 20px;
      width: 100%;
      margin-bottom: 0;

      .menu-item {
        text-align: center;
        text-transform: uppercase;
        font-size: 14px !important;
        margin-bottom: 10px;
        padding-left: 0 !important;
        padding-right: 0 !important;

        &:hover {
          background-color: #f7f7f7 !important;
        }

        .menu-item-dropdown {
          position: relative !important;
          border-top: 1px solid lightgray;
          background-color: #f7f7f7 !important;

          & > li {
            color: $primary-color !important;
            padding: 15px 10px;

            div > a {
              color: $primary-color !important;
              &:hover {
                color: $secondary-color !important;
              }
            }
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

@import "../../assets/scss/styles.scss";

.slider {
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 3s;
}

.sliderTextContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  // padding: 30px;

  & > h3 {
    margin-bottom: 27px !important;
  }
}

.slideImg {
  height: 405px;
  max-width: 100%;
  float: right;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.slider-controls {
  position: fixed;
  bottom: 5%;
  right: 8%;
  width: 25%;
  z-index: 20;

  .right {
    float: right;
  }
}

@media screen and (max-width: 768px) {
  .slider-controls {
    position: relative;
    width: 100% !important;
    margin-top: 3rem;
    left: 1%;
  }

  .slideImg {
    height: inherit;
    width: inherit;
  }
}

@media screen and (max-width: 1024px) {
  .sliderTextContainer {
    padding: 30px 0;
  }
}

@import "../../assets/scss/styles.scss";

.landing {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 97vh;
  padding: 100px 169px;
}

.content {
  max-width: 26.5rem;
  background-color: #00000052;
  padding: 0 12px;
  border-radius: 10px;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 3s;

  h2 {
    padding-top: 0;
  }

  .action-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;

    p {
      margin-bottom: 0;
      font-weight: 700;
    }
  }

  h2,
  p {
    color: $primary-color-dark;
  }
}

.fullwidth-bg {
  width: 100%;
  height: 100vh;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  background: url("../../assets/background-images/dear\ not\ so\ little\ -\ originals\ -dolamubadejo\ bdic\ 3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  right: 8%;
  top: 25%;
  width: 20%;
  background-color: #00000052;
  padding: 12px;
  border-radius: 10px;
  z-index: 10;

  .action-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;

    p {
      margin-bottom: 0;
      font-weight: 700;
    }
  }

  h3,
  p {
    color: $primary-color-dark;
  }

  p {
    font-size: 14px;
  }
}

@media only screen and (min-width: 540px) and (max-width: 600px) {
  .fullwidth-bg {
    background-position: center;
  }

  .hero-container {
    top: 30%;
    width: 50%;
  }
}

@media screen and (max-width: 640px) {
  .landing {
    padding: 16px;
  }
  .content {
    max-width: 100%;
  }
  .hero-container {
    top: inherit;
    right: inherit;
    position: relative;
    width: 100%;
    margin-top: 80px;
  }

  .fullwidth-bg {
    background-position: right;
  }
}

@media only screen and (min-width: 650px) and (max-width: 1024px) {
  .hero-container {
    top: 25%;
    width: 30%;
  }

  .fullwidth-bg {
    background-position: center;
  }
}

@import "../../assets/scss/styles.scss";

.read-more-link {
  color: blueviolet;
  text-decoration: underline;
  letter-spacing: 1px;
  cursor: pointer;
}
.extra-content {
  color: cornflowerblue;
  font-weight: 500;
}

.community-work-header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;

  div {
    width: 50%;
  }
}

.community-work-header-description {
  margin-bottom: 0;
}

.community-work-section {
  padding: 20px;
  padding-right: 105px;
}

.cw-gallery-image-wrapper-1 {
  grid-row: 1/2;
  grid-column: 1/2;
}
.cw-gallery-image-wrapper-2 {
  grid-row: 2/2;
  grid-column: 2/1;
}
.cw-gallery-image-wrapper-3 {
  grid-row: 3/1;
  grid-column: 2/6;
}

.cw-gallery-wrapper {
  grid-template-rows: repeat(2, 170px) !important;
}

.image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0 10px;
  grid-auto-rows: 10px;
}

.image-list img {
  width: 250px;
}

@media screen and (max-width: 800px) {
  .page-title {
    position: relative;
    transform: rotate(0);
    float: left;
    left: inherit;
    top: inherit;
  }

  .mt-2,
  .mt-3 {
    margin-top: 0 !important;
  }
}

// @media screen and (min-width: 800px) {
//   body {
//     overflow-y: auto;
//   }
// }
@media screen and (max-width: 400px) {
  .live-header-menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 2rem;
    justify-content: space-between;

    p {
      padding-right: 5px;
      &:last-of-type {
        padding-right: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .community-work-header-section {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 4rem;

    div {
      width: 100%;
    }
  }

  .community-work-header-description {
    width: 100%;
  }

  .community-work-section {
    padding: 15px;
    padding-right: 15px;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 16px !important;
  color: $primary-color;
  letter-spacing: -0.03em;
  font-family: $second-font-family;
}

h1,
.h1 {
  font-size: 3.6rem !important;
  line-height: 47px !important;
}

h2,
.h2 {
  font-size: 2.5rem !important;
  line-height: 32px;
}

h3,
.h3 {
  font-size: 2.4rem !important;
  line-height: 31px !important;
  font-weight: normal !important;
}

h4,
.h4 {
  font-size: 2.25rem !important;
}

h5,
.h5 {
  font-size: 2rem !important;
  letter-spacing: -0.025rem;
}

h6,
.h6 {
  font-size: 1.6rem !important;
  letter-spacing: -0.01rem;
}

p {
  font-family: $font-family;
  font-weight: 400;
  font-size: 1.4rem !important;
  line-height: 19px;
}

a {
  text-decoration: none !important;
  color: $primary-color !important;
  transition: color 0.3s ease;

  &:hover {
    color: $secondary-color !important;
  }
}

.bold {
  font-weight: 500;
}

.very-bold {
  font-weight: 700;
}

.italics {
  font-style: italic;
}

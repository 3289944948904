@import "../../assets/scss/styles.scss";

.about-page-title {
  position: absolute;
  top: 7% !important;
  left: -21% !important;
  transform: rotate(-90deg);
  float: left;
}

.about-headline-text {
  text-align: right;
}

.about-paragraph {
  padding-left: 10px !important;
  width: 85%;
}

@media screen and (max-width: 800px) {
  .about-page-title {
    position: relative !important;
    transform: rotate(0) !important;
    float: left !important;
    left: 0 !important;
    top: 0 !important;
    width: 100%;
  }

  .about-headline-text {
    text-align: left !important;
    float: left !important;
    width: 80vw;
  }

  .about-paragraph {
    width: 100% !important;
  }
}

// @media screen and (min-width: 768px) {
//   body {
//     overflow-y: auto;
//   }
// }

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .about-page-title {
    left: -4% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 980px) {
  .about-page-title {
    position: relative;
    // top: 7% !important;
    left: 0 !important;
    transform: none;
    float: left;
  }
  .about-headline-text {
    text-align: left !important;
  }
}

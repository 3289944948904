.fullwidth-semi-dark-bg {
  width: 100%;
  height: 100vh;
  /* z-index: -1; */
  /* position: fixed; */
  /* top: 0;
  left: 0; */
  background: url("../../assets/background-images/background\ with\ stars\ \ 1.png");
  background-repeat: no-repeat;
  background-size: contain;
}

@media only screen and (max-width: 1000px) {
  .page-wrapper {
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 800px) {
  .fullwidth-semi-dark-bg {
    width: 100%;
    height: 33vh;
    /* z-index: -1; */
    /* position: fixed; */
    /* top: 0;
    left: 0; */
    background: url("../../assets/background-images/background\ with\ stars\ \ 1.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

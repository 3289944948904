@for $i from 0 through 15 {
  .mt-#{$i} {
    margin-top: #{$i}rem !important;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}rem !important;
  }

  .ml-#{$i} {
    margin-left: #{$i}rem !important;
  }

  .mr-#{$i} {
    margin-right: #{$i}rem !important;
  }

  .pt-#{$i} {
    padding-top: #{$i}rem !important;
  }

  .pb-#{$i} {
    padding-bottom: #{$i}rem !important;
  }
  .pr-#{$i} {
    padding-right: #{$i}rem !important;
  }
  .pl-#{$i} {
    padding-left: #{$i}rem !important;
  }
}

@import "../../assets/scss/styles.scss";

.read-more-link {
  color: blueviolet;
  text-decoration: underline;
  letter-spacing: 1px;
  cursor: pointer;
}

.client-details-page-wrapper {
  width: 100%;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 3s;

  @media screen and (max-width: 800px) {
    .client-details-page-wrapper {
      margin-top: 4rem;
    }
  }
}

.client-input {
  width: 100%;
}
.extra-content {
  color: cornflowerblue;
  font-weight: 500;
}

.client-gallery-carousel {
  height: 300px;
}

.client-full-info-carousel {
  // height: 300px;
}

.client-full-info-img {
}

.client-app-container {
  // align-items: initial;
}

.community-work-header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;

  div {
    width: 50%;
  }
}

.carousel-image-overlay {
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)) !important;
}

.full-info-content-container {
  @media screen and (max-width: 800px) {
    width: 100% !important;
  }
}

.full-info-content {
  min-height: 300 !important;
  width: 100%;
  flex: 1;
  border-width: 1;

  @media screen and (max-width: 800px) {
    height: initial;
  }
}

.client-details-img {
  width: 100%;
  height: 300px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
  cursor: zoom-in;

  &.client-details-modal-img {
    height: 80vh;
    background-size: contain !important;
    cursor: zoom-out !important;
  }
}

.client-upcoming-event-date {
  color: $secondary-color;
}

.side-menu-row {
  border: 1px solid blue;
}
.side-menu-content {
}

.client-full-info-img {
  width: 100%;
  height: 400px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center;
  cursor: zoom-in;

  .client-full-info-modal-img {
    height: 100%;
    background-size: cover !important;
    cursor: zoom-out !important;
  }
}

.client-modal-img {
  background: transparent;
  height: 100% !important;
  border: none;

  .close {
    color: $primary-color-dark;
  }
}

.client-details-modal-img-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.schedule-call-container {
  min-height: 300px;
}

.client-side-menu {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  text-align: right;

  @media screen and (max-width: 800px) {
    text-align: left;
    align-items: flex-start;
  }
}

.side-menu-link {
  font-family: $third-font-family;
  &.active {
    color: $secondary-color;
  }
}

.client-details-modal-img-description {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $primary-color-dark;
  margin-top: 5px;
  padding: 10px;
  width: 100%;
  align-self: center;

  p {
    color: $primary-color;
  }
}

.client-details-modal-img-header {
  border: none;
}

.community-work-header-description {
  margin-bottom: 0;
}

.community-work-section {
  padding: 20px;
  padding-right: 105px;
}

.cw-gallery-image-wrapper-1 {
  grid-row: 1/2;
  grid-column: 1/2;
}
.cw-gallery-image-wrapper-2 {
  grid-row: 2/2;
  grid-column: 2/1;
}
.cw-gallery-image-wrapper-3 {
  grid-row: 3/1;
  grid-column: 2/6;
}

.cw-gallery-wrapper {
  grid-template-rows: repeat(2, 170px) !important;
}

.image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0 10px;
  grid-auto-rows: 10px;
}

.image-list img {
  width: 250px;
}

@media screen and (min-width: 1024px) {
  #wrapper {
    width: 100%;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      background: linear-gradient(to bottom, transparent, white);
      height: 40%;
      width: 100%;
      pointer-events: none;
    }

    &.less-before-fade::before {
      content: "";
      position: absolute;
      bottom: 0;
      background: linear-gradient(to bottom, transparent, white);
      height: 10%;
    }
  }

  #client-scroller {
    width: 100%;
    height: 350px !important;
    overflow-y: auto;

    .live-detatils-text {
      margin-bottom: 100px;
    }
  }
}

@media screen and (max-width: 800px) {
  .page-title {
    position: relative;
    transform: rotate(0);
    float: left;
    left: inherit;
    top: inherit;
  }

  .mt-2,
  .mt-3 {
    margin-top: 0 !important;
  }

  .client-details-img {
    width: 90vw !important;
    height: 400px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    margin-bottom: 20px;
  }
}

// @media screen and (min-width: 800px) {
//   body {
//     overflow-y: auto;
//   }
// }
@media screen and (max-width: 400px) {
  .live-header-menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 2rem;
    justify-content: space-between;

    p {
      padding-right: 5px;
      &:last-of-type {
        padding-right: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .community-work-header-section {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 4rem;

    div {
      width: 100%;
    }
  }

  .community-work-header-description {
    width: 100%;
  }

  .community-work-section {
    padding: 15px;
    padding-right: 15px;
  }
}

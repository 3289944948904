@import "../../assets/scss/styles.scss";

.sc-page-wrapper {
  width: 100%;
  padding: 105px;
}
.sc-section {
  width: 100%;
}
.sc-header-section {
  margin-bottom: 4rem;
}
.sc-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.sc-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  p {
    margin-top: 20px;
  }
}

@media screen and (max-width: 300px) {
  .sc-content {
    justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .sc-page-wrapper {
    width: 100%;
    padding: 20px;
    padding-bottom: 50px;
  }
}

html,
body,
#app,
#app > div {
  height: 100vh;
}

.animated-div {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}

[data-reactroot] {
  height: 100% !important;
}
html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  margin: 0;
}

img {
  /* pointer-events: none !important; */
}

.slide-enter,
.slide-exit {
  transition: translate3d(100%, 0, 0);
}

.slide-enter {
  transform: translate3d(0%, 0, 0);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate3d(0%, 0, 0);
}

.slide-exit-active {
  transform: translate3d(-50%, 0, 0);
}

.fade-enter .btn {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active .btn {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit .btn {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active .btn {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active .btn,
.fade-exit-active .btn {
  transition: opacity 500ms, transform 500ms;
}

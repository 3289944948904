@media screen and (max-width: 530px) {
  footer > .footer-container > .arrow-container > .row {
    div:last-child {
      padding-right: 0 !important;
      justify-content: flex-end !important;
    }
  }
}
@media screen and (max-width: 800px) {
  footer {
    padding: 10px 0;

    .footer-container.reverse {
      flex-direction: column-reverse !important;
    }

    &.with-reverse {
      padding-right: 0;
    }

    .copyright {
      max-width: 1440px;
      padding: 20px;
      font-size: 12px !important;
      line-height: 16px;
      color: white;
      background-color: $primary-color;
      text-align: left;
      padding-left: 15px;
      padding-right: 15px;
    }

    .copyright-reverse {
      text-align: left;
      padding-right: 0;
    }
    .app-container {
      width: 100%;
    }

    &.sticky {
      bottom: 0;
      position: relative !important;
      padding-bottom: 0;
    }

    .footer-container {
      display: flex;
      flex-direction: column-reverse;
      .arrow-container {
        padding: 20px 15px;
        width: 100%;

        .row {
          width: 100% !important;
          justify-content: space-between !important;
          div {
            width: 50% !important;
            display: flex;
          }

          div:last-child {
            padding-right: 0 !important;
          }
        }
      }
    }

    .footer-container > .footer-menu {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
      margin-bottom: 0;

      .footer-menu-item {
        text-align: left;
        width: 100%;
        background-color: $primary-color;
        color: white;
        padding: 20px 15px;

        &:hover {
          background-color: $primary-color;
          color: white;
          text-align: left;
        }

        .footer-menu-item-dropdown {
          position: relative;
          display: block;
          min-height: 100%;
          padding-bottom: 10px !important;
          padding-left: 0 !important;
        }
      }

      .footer-menu-item-dropdown-mega-menu {
        display: flex !important;
        position: relative !important;
        padding-bottom: 0 !important;
        padding: 10px 0;
        padding-left: 10px !important;
        justify-content: left !important;

        .row {
          width: inherit !important;
          // justify-content: space-between !important;
          // align-items: flex-start;
          // flex-wrap: wrap;

          .row-item.col-md-4 {
            width: 33.33% !important;
            padding-left: 0 !important;

            &:first-child {
            }
          }
          .row-item {
            text-align: left !important;
            margin-bottom: 0;

            &:last-of-type {
              margin-bottom: 0 !important;
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@import "../../assets/scss/styles.scss";

.packages {
  display: flex;
  align-items: center;
}

.approach-app-container {
  z-index: 100 !important;
}

.approach-container {
  z-index: 11 !important;
}

.approach-edge-image {
  position: absolute;
  bottom: 0;
  right: -10%;
  z-index: 0 !important;
  img {
    object-fit: contain;
  }
}

@media screen and (max-width: 800px) {
  .approach-edge-image {
    display: none;
  }
}
@media screen and (min-width: 760px) and (max-width: 800px) {
  .approach-edge-image {
    display: none;
  }
}

.app-container {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  padding: 0 69px 10px 170px;
}

.row {
  margin: 0 !important;
}

.primary-color-dark {
  color: $primary-color-dark;
}

.no-padding-left {
  padding-left: 0 !important;
}

ul {
  list-style: none;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
}

.sticky {
  position: fixed !important;
}

.gallery-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 200px);
  grid-gap: 20px;
}

.gallery-image-wrapper {
  overflow: hidden;

  div > p,
  a {
    color: $primary-color-dark !important;
    margin-bottom: 0.5rem;
  }
}

.gallery-image {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center;
  padding: 5px 25px;
  width: 100%;
  height: 100%;
  transition: transform 0.4s;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 3s;
  &:hover {
    transform: scale(1.05);
    transform-origin: 50% 50%;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   background: linear-gradient(to bottom, transparent, black);
    //   height: 100%;
    //   width: 100%;
    //   pointer-events: none;
    // }
  }
}

.gallery-image-wrapper-1 {
  // height: 417px;
  grid-row: 1/3;
  grid-column: 1/2;
}
.gallery-image-wrapper-2 {
  // height: 200px;
}
.gallery-image-wrapper-3 {
  // height: 200px;
}
.gallery-image-wrapper-4 {
  // height: 200px;
}
.gallery-image-wrapper-5 {
  // height: 200px;
}

@media screen and (max-width: 800px) {
  .gallery-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .gallery-image-wrapper-1 {
    // height: 417px;
    grid-row: 1/2;
    grid-column: 1/2;
  }
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 800px) {
  .gallery-wrapper {
    display: flex;
    flex-direction: column;
  }

  .gallery-image-wrapper {
    height: 300px;
    margin-bottom: 20px;
  }
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  width: inherit !important;
}

@-webkit-keyframes bounceDown {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounceDown {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@-webkit-keyframes bounceLeft {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  60% {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
}
@keyframes bounceLeft {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(30px);
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
  60% {
    -ms-transform: translateX(15px);
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
}
/* /left bounce */

/* right bounce */
@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px);
  }
  60% {
    -ms-transform: translateX(-15px);
    -webkit-transform: translateX(-15px);
    transform: translateX(-15px);
  }
}
/* /right bounce */

/* assign bounce */
.bounce-right {
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite;
  float: right;
}

.bounce-left {
  -webkit-animation: bounceLeft 2s infinite;
  animation: bounceLeft 2s infinite;
}

.bounce-down {
  -webkit-animation: bounceDown 2s infinite;
  animation: bounceDown 2s infinite;
  text-align: center;
  display: block;
}

.credits {
  padding-top: 50px;
  display: block;
  clear: both;
}

@media screen and (max-width: 640px) {
  .bounce-right {
    -webkit-animation: bounceRight 3s infinite;
    animation: bounceRight 3s infinite;
    float: right;
  }

  .bounce-left {
    -webkit-animation: bounceLeft 3s infinite;
    animation: bounceLeft 3s infinite;
  }

  .bounce-down {
    -webkit-animation: bounceDown 3s infinite;
    animation: bounceDown 3s infinite;
    text-align: center;
    display: block;
  }
}

@media screen and (max-width: 800px) {
  .app-container {
    padding: 80px 0 0 0 !important;
    align-items: flex-start !important;
    min-height: inherit;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.input-title {
  text-transform: uppercase;
  &.dark {
    color: $primary-color !important;
  }
}

.input {
  // height: 31px;
  width: 400px;
  background-color: $primary-color-dark;
  border: none;
  padding: 8px;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 1.3;

  &:focus {
    border: 1px solid $secondary-color;
    outline: none !important;
  }
}

.input.dark {
  background-color: $primary-color;
  color: $primary-color-dark;
}

.input.dark.outline {
  border: 1px solid $primary-color;
  color: $primary-color !important;
  background-color: transparent !important;
}

.continue-btn {
  display: flex;
  background-color: $primary-color-dark;
  padding: 4px 5px;
  align-items: center;
  width: 100%;
  justify-content: center;
  cursor: pointer;
}

.continue-btn.dark {
  background-color: $primary-color;
  color: $primary-color-dark !important;
  a {
    color: $primary-color-dark !important;
  }
}

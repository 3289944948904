@import "../../assets/scss/styles.scss";

.contact-container {
  padding: 0 0 0 0 !important;
}

.appointments-landing-container {
  padding: 0 69px 10px 170px;
}

.rb-landing-container {
  padding: 0 69px 10px 170px;
}

.appointment-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 150px;
  h1,
  p {
    width: 100%;
  }
}

.contact-input {
  width: 400px;
}

.faq-input {
  width: 280px;
}

.input-container {
}

.form-error-text {
  color: $secondary-color;
  margin-bottom: 2px;
}

textarea.input {
  height: inherit;
}

.input-title {
  font-size: 1.1rem !important;
  color: $primary-color-dark;
  margin-bottom: 5px;
}

.input-small-text {
  font-size: 1.1rem !important;
  width: 400px;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.faq-btn-wrapper {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.faq-title {
  color: white;
}

.faq-sub-title {
  padding-left: 2rem;
  color: white;
  font-size: 11px !important;
}

.with-back-btn {
  // justify-content: space-between !important;
}

.back-btn {
  display: flex;
  background-color: $primary-color-dark;
  padding: 4px 10px;
  align-items: center;
  width: inherit;
  justify-content: left;
  cursor: pointer;
}

.contact-continue-btn {
  // width: inherit !important;
  width: 160px !important;
}
// .continue-btn {
//   display: flex;
//   background-color: $primary-color-dark;
//   padding: 4px 10px;
//   align-items: center;
//   width: inherit;
//   justify-content: right;
//   cursor: pointer;
// }

.rb-request-btn {
  width: 294px !important;
}

.contact-request-btn {
  width: 294px;
}

.request-br-btn-wrapper {
  margin-top: 3rem;
}

.rb-form-wrapper {
  padding-left: 6rem;
}
.form-wrapper {
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.faq-form-wrapper {
  justify-content: flex-start !important;
  padding-left: 20px;
}

.faq-card {
  background-color: transparent !important;
  border: none !important;
  padding-left: 0 !important;
  & > .faq-card-header {
    padding: 0 !important;
    background-color: transparent !important;

    & > .btn {
      padding-left: 0 !important;
      color: white !important;
      font-size: 11px;
    }
  }

  .faq-card-header.faq-card-header-with-margin {
    margin-bottom: 1rem !important;
  }
}

@media screen and (max-width: 800px) {
  // .input {
  //   height: 31px;
  //   width: 100%;
  //   background-color: $primary-color-dark;
  //   border: none;
  //   padding: 8px;
  // }

  .contact-input,
  .faq-input {
    width: 100% !important;
  }

  // .contact-request-btn {
  //   width: 100%;
  // }

  .input-small-text {
    font-size: 1.1rem !important;
    width: 100%;
  }
  .form-container {
    width: 100%;
  }

  .rb-landing-container {
    padding: 80px 0 0 0 !important;
    align-items: flex-start !important;
    min-height: inherit;
  }

  .appointments-landing-container {
    padding: 80px 0 0 0 !important;
    align-items: flex-start !important;
    min-height: inherit;
  }

  .appointment-title-container {
    padding-left: 0 !important;
  }

  .request-btn {
    width: 100%;
  }

  .rb-request-btn {
    width: inherit !important;
  }

  .request-br-btn-wrapper {
    margin-top: 3rem;
  }

  .contact-continue-btn {
    width: 100% !important;
  }

  .contact-request-btn {
    width: 100% !important;
  }

  .back-btn {
    width: 100% !important;
  }

  .faq-form-wrapper,
  .rb-form-wrapper {
    padding-left: 0 !important;
  }

  .rb-form-wrapper {
    width: 85vw;
  }
}

@import "../../assets/scss/styles.scss";

.philosphy-container {
  z-index: 11;
}

.page-title {
  position: absolute;
  top: 0;
  left: 30%;
  transform: rotate(-90deg);
}

.rotate-text-90 {
  transform: rotate(-90deg);
  //   transform-origin: 0 0;
}

.image {
  img {
    object-fit: contain;
    height: auto;
    width: 100%;
    object-position: top;
  }
}

p.smallerText {
  font-size: 13px !important;
}

.edge-image {
  img {
    z-index: 10;
    position: fixed;
    object-fit: contain;
    bottom: 0;
    right: 0;
  }
}

@media screen and (max-width: 768px) {
  .page-title {
    position: relative;
    transform: rotate(0);
    float: left;
    left: inherit;
    top: inherit;
  }

  .partner-slideImg1 {
    width: 100% !important;
    height: inherit !important;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .mt-2,
  .mt-3 {
    margin-top: 0 !important;
  }
}

// @media screen and (min-width: 768px) {
//   body {
//     overflow-y: auto;
//   }
// }

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .partner-slideImg1 {
    width: 100% !important;
    height: inherit !important;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

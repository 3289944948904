@import "../../assets/scss/styles.scss";

.jt-app-container {
  justify-content: center;
  align-items: center;
}

.jt-form-wrapper {
  background-color: $primary-color;
  border: 1px solid white;
  padding: 38px;
  width: 80%;

  .row {
    align-items: center;
  }
}

.jt-landing-container {
  align-items: center;
}
.jt-title-container {
  padding-left: 150px;
  justify-content: flex-end;
  align-items: flex-end;

  h1 {
    color: $primary-color-dark;
  }
  div {
    color: $primary-color;
  }
}

.appointments-landing-container {
  padding: 0 69px 10px 170px;
}

.appointment-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 150px;
  h1,
  p {
    width: 100%;
  }
}

.jt-input {
  width: 100% !important;
}

.faq-input {
  width: 280px;
}

.input-container {
}

.form-error-text {
  color: $secondary-color;
  margin-bottom: 2px;
}

textarea.input {
  height: inherit;
}

.input-title {
  font-size: 1.1rem !important;
  color: $primary-color-dark;
  margin-bottom: 5px;
}

.input-small-text {
  font-size: 1.1rem !important;
  width: 400px;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
.faq-btn-wrapper {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.faq-title {
  color: white;
}

.faq-sub-title {
  padding-left: 2rem;
  color: white;
  font-size: 11px !important;
}

.jt-request-btn {
  width: 270px;
}

.jt-request-br-btn-wrapper {
  margin-top: 1rem;
}

.form-wrapper {
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.faq-form-wrapper {
  justify-content: flex-start !important;
  padding-left: 20px;
}

.faq-card {
  background-color: transparent !important;
  border: none !important;
  padding-left: 0 !important;
  & > .faq-card-header {
    padding: 0 !important;
    background-color: transparent !important;

    & > .btn {
      padding-left: 0 !important;
      color: white !important;
      font-size: 11px;
    }
  }

  .faq-card-header.faq-card-header-with-margin {
    margin-bottom: 1rem !important;
  }
}

@media screen and (max-width: 800px) {
  .jt-app-container {
    justify-content: inherit;
    align-items: flex-start;
    width: 100% !important;
  }
  .input {
    width: 100%;
    background-color: $primary-color-dark;
    border: none;
    padding: 8px;
  }

  .input-small-text {
    font-size: 1.1rem !important;
    width: 100%;
  }
  .form-container {
    width: 100%;
  }

  .appointments-landing-container {
    padding: 80px 0 0 0 !important;
    align-items: flex-start !important;
    min-height: inherit;
  }

  .request-btn {
    width: 100%;
  }

  .request-br-btn-wrapper {
    margin-top: 3rem;
  }

  .jt-form-wrapper {
    background-color: #000000;
    border: 1px solid white;
    padding: 38px;
    width: 100%;
  }

  .jt-title-container {
    padding: 0;
    padding-top: 70px;
  }

  .jt-request-btn {
    width: 100%;
  }
}

.jt-landing-container-row {
  display: flex;

  .jt-landing-container-col {
    width: 45%;
    margin-left: 30px;
  }
}

// @media screen and (max-width: 320px) {
//   .jt-title-container {
//     padding: 0;
//     padding-top: 35px;
//   }
// }

// @media screen and (min-width: 990px) and (max-width: 1200px) {
//   .jt-title-container {
//     padding-top: 5px;
//   }
// }
@media screen and (max-width: 800px) {
  .jt-landing-container-row {
    display: flex;
    flex-direction: column;

    .jt-landing-container-col {
      width: 100%;
      margin-left: 0;
    }
  }
}

@media screen and (width: 1024px) {
  .jt-header-text {
    color: $primary-color !important;
  }
}

@media screen and (max-width: 400px) {
  .jt-landing-container {
    margin-top: 10px;
  }
}
@media screen and (min-width: 410px) and (max-width: 500px) {
  .jt-landing-container {
    margin-top: 40px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  .jt-landing-container {
    margin-top: 155px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .jt-title-container {
    padding-left: 0;
    padding: 38px;
  }

  .jt-form-wrapper {
    background-color: #000000;
    border: 1px solid white;
    // padding-top: 38px;
    width: 100%;
  }

  // .jt-title-container {
  //   padding: 0;
  //   padding-top: 10px;
  // }

  .jt-input {
    width: 100%;
  }
}

@media screen and (min-width: 1250px) and (max-width: 1400px) {
  .jt-landing-container {
    margin-top: 100px !important;
  }
}

@media screen and (min-width: 310px) and (max-width: 370px) {
  .jt-app-container {
    padding-top: 10px !important;
  }
  .jt-landing-container {
    margin-top: -20px !important;
  }
}

@media screen and (min-width: 374px) and (max-width: 399px) {
  .jt-landing-container {
    margin-top: 50px !important;
  }
}

@media screen and (min-width: 374px) and (max-width: 375px) {
  .jt-landing-container {
    margin-top: 10px !important;
  }
}

// @media screen and (min-width: 320px) and (max-width: 371px) {
//   .jt-landing-container {
//     margin-top: 45px !important;
//   }
// }

@import "../../assets/scss/styles.scss";

.philosphy-container {
  z-index: 11 !important;
}

.principles-page-title {
  // transform: rotate(-90deg);
  position: absolute;
  top: -33%;
  white-space: nowrap;
  // transform-origin: bottom;
  transform: translateX(-100%) rotate(-90deg);
  transform-origin: right;
}

.our-promise-page-title {
  position: absolute;
  white-space: nowrap;
  transform: translateX(-100%) rotate(-90deg);
  transform-origin: right;
  font-weight: normal;
  left: 10%;
  padding-top: 0 !important;
}

.principle-page-title2 {
  top: -19% !important;
}

.principle-text-align-left {
  text-align: left;
  margin-top: 6rem;
  margin-left: 5rem;
}

.principle-text-align-left-2 {
  margin-left: 3rem;
}

.principles-text-footer {
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 3rem;
  margin-left: 7rem;
}

.principles-text-footer-2 {
  margin-left: 5rem;
}

.rotate-text-90 {
  transform: rotate(-90deg);
  //   transform-origin: 0 0;
}

.our-promise-image {
  height: 514px;
  max-width: 100%;
  float: right;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

p.smallerText {
  font-size: 13px !important;
}

.principles-edge-image {
  z-index: 5;
  img {
    position: fixed;
    object-fit: contain;
    bottom: 0;
    right: 0;
  }
}

.row-margin {
  padding-top: 0;
}

@media screen and (max-width: 768px) {
  .our-promise-image {
    height: inherit;
  }

  .principles-page-title,
  .our-promise-page-title {
    position: relative;
    transform: none;
    float: left;
    left: inherit;
    top: inherit;
    width: 100%;
  }

  .principle-text-align-left {
    margin-left: 0;
    margin-top: 0;
  }

  .principles-text-footer {
    width: 100%;
    margin-left: 0 !important;
    padding: 15px;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  .row-margin {
    padding-top: 3rem;
  }

  .principles-edge-image {
    z-index: 1;
    img {
      position: relative;
      object-fit: contain;
      bottom: 0;
      right: 0;
    }
  }

  .mt-2,
  .mt-3 {
    margin-top: 0 !important;
  }

  .principles-text-footer-2,
  .principle-text-align-left-2 {
    margin-left: 0 !important;
  }
}

// @media screen and (min-width: 768px) {
//   body {
//     overflow-y: auto;
//   }
// }

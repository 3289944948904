nav {
  width: 100%;
  padding: 44px 44px 73px 69px;
  z-index: 100;

  .menu-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1440px;
    margin: 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 1rem;

    .logo {
      width: 109px;
      height: 40px;
      background: url("../../../images/logos/logo.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      padding: 1rem;
    }

    #mobile-menu {
      display: none;
    }

    .menu {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      margin-bottom: 0;

      .menu-item {
        padding: 7px 25px;
        font-family: $third-font-family;
        text-transform: uppercase;
        font-size: 12px;

        .menu-item-dropdown {
          display: none;
          position: absolute;
          margin-top: 5px;
          background-color: #000000e0;
          font-family: $font-family;
          font-size: 14px;
          font-weight: 700;

          & > li {
            color: white;
            padding: 15px 10px;

            a {
              color: white !important;
              &:hover {
                color: $secondary-color !important;
              }
            }

            ul {
              display: none;

              li {
                margin-top: 10px;
                padding: 10px;
                font-weight: normal;
                text-transform: capitalize;

                &:hover > div {
                  color: $secondary-color;
                }
              }
            }

            &:hover ul {
              display: block;
            }

            &:hover > div {
              color: $secondary-color !important;
            }
          }

          &:hover li {
            display: block;
          }
        }

        &:hover .menu-item-dropdown {
          display: block;
        }

        &:hover > div {
          color: $secondary-color;
        }
      }
    }
  }

  &.sticky {
    top: 0;
  }
}
